.checkout{
    background-color: rgb(245, 245, 253);
    height: 35vw;
    width: 34vw;
    margin-top: 2.5vw;
    margin-left: 3.5%;
    border-radius: 0.3vw;
    /*padding: 1%;*/
    /*position: fixed;*/
    /*border-left: 0.2vw solid rgb(150, 0, 0);*/
    box-shadow: 0 0 0.17vw 0.08vw rgba(0,0,0,0.17);
    border-bottom: 0.35vw solid darkred;
}

.finalizar-compra{
    background-color: darkred;
    height: 2.7vw;
    width: 80%;
    padding: 1%;
    margin-top: 7%;
    margin-left: 10%;
    font-size: 1.2vw;
    border-radius: 0.4vw;
    box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.2);
    color: white;
    border: none;
}
.cancelar-compra{
    background-color: rgb(109, 109, 119);
    height: 2.7vw;
    width: 80%;
    padding: 1%;
    margin-top: 5%;
    margin-left: 10%;
    font-size: 1.2vw;
    border-radius: 0.4vw;
    box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.2);
    color: white;
    border: none;
}
.precio-smarket-container{
    background-color: white;
    border-left: 0.35vw solid rgb(248, 0, 0);
    border-right: 0.35vw solid rgb(248, 0, 0);
    /*background-color: rgb(248, 0, 0);*/
    /*color: white;*/
    /*border: 0.3vw solid white;*/
    height: 5vw;
    width: 85%;
    margin: 2.5vw auto 7% auto;
    border-radius: 0.7vw;
    box-shadow: 0 0 0.1vw 0.05vw rgba(0,0,0,0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.precio-smarket-container img{
    width: 7vw;
    margin-left: 4%;
}
.precio-ahorro-container {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    width: 50%;
}
.precio-smarket{
    font-size: 1.1vw;
    display: flex;
}
.numero-smarket{
    font-weight: bold;
    margin-left: 10%;
}
.ahorro-smarket{
    font-weight: bold;
    font-size: 1.1vw;
}
.ahorro-container {
    display: flex;
}
.ahorro-texto{
    font-weight: 400;
    color: red;
}
.ahorro {
    color: red;
}
.precio-super-container{
    background-color: rgb(255, 255, 255);
    height: 5vw;
    width: 85%;
    margin: 0 auto 7% auto;
    border-radius: 0.7vw;
    box-shadow: 0 0 0.1vw 0.05vw rgba(0,0,0,0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.precio-super-container img{
    width: 6.5vw;
    margin-left: 4%;
}
.info{
    width: 26vw;
    /*height: 10vw;*/
    background-color: white;
    font-size: 0.9vw;
    border-radius: 0.3vw;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 1vw;
    box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);
    position: absolute;
    margin-top: 1vw;
    margin-left: 3vw;
}
.precio-super{
    font-size: 1.1vw;
    color: #838383;
    display: flex;
    width: 50%;
}
.numero-super{
    font-weight: bold;
    margin-left: 4%;
    /*margin-top: -1.3vw;*/
}
.supers{
    /*background-color: rgb(230, 230, 240);*/
    height: 14vw;
    width: 100%;
    border-radius: 3%;
    margin-top: 4%;
    /*overflow: scroll;*/
    /*scroll-snap-type: y mandatory;*/
    /*overflow-x: hidden;*/
}
::-webkit-scrollbar {
    width: 0.8vw;
    min-width: 5px;
    max-width: 12px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
    /*box-shadow: 0 0 0.05vw 0.1vw rgba(0,0,0,0.1);*/
    padding: 0.7vw;

}
::-webkit-scrollbar-thumb {
    /*background-image: linear-gradient(180deg, rgb(190, 190, 200) 10%, red 90%);*/
    background-color: darkred;
}
.show-more-button{
    text-align: center;
    width: 20%;
    margin: 7% 40% 5%;
    color: darkred;
    background: transparent;
    font-size: 1.1vw;
    border: none;
}
.white-rectangle2{
    background-color: white;
    width: 1520px;
    margin-left: -1035px;
    height: 53px;
    margin-top: 38px;
}


@media(max-device-width: 700px){

    .checkout {
        background-color: rgb(245, 245, 253);
        height: 200px;
        width: 100%;
        flex: 33;
        margin-top: 2.5vw;
        margin-left: -2.5%;
        border-radius: 0.3vw;
        padding: 0;
        /*position: fixed;*/
        /*border-left: 0.2vw solid rgb(150, 0, 0);*/
        border-bottom: none;
        position: fixed;
        bottom: 0;
        box-shadow: 0 -2px 8px 1px rgba(0,0,0,0.15);
    }

    .finalizar-compra {
        background-color: darkred;
        height: 40px;
        width: 90%;
        padding: 1%;
        margin: 10px 5% 0;
        font-size: 15px;
        font-weight: 600;
        border-radius: 7px;
        box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.2);
        color: white;
        border: none;
    }

    .cancelar-compra {
        background-color: rgb(109, 109, 119);
        height: 40px;
        width: 90%;
        padding: 1%;
        margin: 10px 5% 0;
        font-size: 15px;
        border-radius: 7px;
        box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.2);
        color: white;
        border: none;
    }

    .precio-smarket-container{
        background-color: white;
        border-left: 3px solid rgb(248, 0, 0);
        border-right: 3px solid rgb(248, 0, 0);
        /*background-color: rgb(248, 0, 0);*/
        /*color: white;*/
        /*border: 0.3vw solid white;*/
        height: 45px;
        width: 90%;
        margin: 15px auto 0 auto;
        border-radius: 10px;
        box-shadow: 0 0 1px 1px rgba(0,0,0,0.15);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .precio-smarket-container img {
        width: 90px;
        margin-top: 0;
        margin-left: 5px;
    }

    .precio-smarket {
        margin-top: 0;
        margin-left: 0;
        font-size: 15px;
        display: flex;
    }

    .numero-smarket {
        font-weight: bold;
        margin-left: 10px;
        margin-top: 0;
    }

    .ahorro-smarket {
        font-weight: bold;
        margin-left: 0;
        margin-top: 0;
        color: red;
        font-size: 15px;
        margin-right: 15px;
    }

    .precio-super-container {
        background-color: rgb(255, 255, 255);
        height: 45px;
        width: 90%;
        margin: 0 auto 7% auto;
        border-radius: 10px;
        box-shadow: 0 0 1px 1px rgba(0,0,0,0.15);
        display: flex;
        align-items: center;
        justify-content: space-between;

        display: none;
    }

    .precio-super-container img {
        width: 90px;
        margin-top: 0;
        margin-left: 5px;
    }

    .info {
        width: 20%;
        /*height: 10vw;*/
        background-color: white;
        font-size: 0.9vw;
        border-radius: 0.3vw;
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 1vw;
        box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);
        position: absolute;
        margin-top: -1vw;
        margin-left: 2%;
    }

    .precio-super {
        margin-top: 0;
        margin-left: 0;
        font-size: 15px;
        color: #838383;
        display: flex;
        margin-right: 15px;
    }

    .numero-super {
        font-weight: bold;
        margin-left: 10px;
        /*margin-top: -1.3vw;*/
    }

    .supers {
        /*background-color: rgb(230, 230, 240);*/
        height: 60px;
        width: 100%;
        border-radius: 3%;
        margin-top: 4%;
        /*overflow: scroll;*/
        /*scroll-snap-type: y mandatory;*/
        /*overflow-x: hidden;*/
    }

    .show-more-button {
        text-align: center;
        margin: 2% 41% 3%;
        color: darkred;
        background: transparent;
        font-size: 1.1vw;
        border: none;

        display: none;
    }
}