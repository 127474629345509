.button-container {
    display: flex;
    width: 50%;
    margin: 1.5vw auto -2vw;
}
.custom-button-left {
    flex: 1;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    /*box-shadow: -0.05vw 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
    border-radius: 0.5vw 0 0 0.5vw;
    padding: 0.6vw;
    cursor: pointer;
    background-color: rgb(245, 245, 253);
    background-image: linear-gradient(to left, darkred 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: background-position 0.3s ease;
    color: darkred;
    font-size: 1vw;
}
.custom-button-right {
    flex: 1;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    /*box-shadow: 0.05vw 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
    border-radius: 0 0.5vw 0.5vw 0;
    padding: 0.65vw;
    cursor: pointer;
    background-color: rgb(245, 245, 253);
    background-image: linear-gradient(to right, darkred 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.3s ease;
    color: darkred;
    font-size: 1vw;
}
.custom-button-left.selected {
    background-position: right bottom;
    color: white;
}
.custom-button-right.selected {
    background-position: left bottom;
    color: white;
}
.delivery-container{
    width: 75%;
    border-radius: 0.3vw;
    /*background-color: rgb(245, 245, 253);*/
    background-color: rgb(245, 245, 253);
    margin: 3vw auto -2vw;
    padding-bottom: 2vw;
    box-shadow: 0 0 0.15vw 0.07vw rgb(0, 0, 0, 0.2);
    /*border-top: 0.2vw solid darkred;*/
    /*border-bottom: 0.2vw solid darkred;*/
}
.delivery-title{
    padding: 1vw 0 0.7vw 2vw;
    border-bottom: 0.1vw solid #ccc;
    margin-bottom: -2vw;
    font-size: 0.75vw;
}
.delivery-details{
    padding: 2.5vw;
}
.delivery-fields{
    display: flex;
    gap: 2vw;
    align-items: center;
    margin: 1vw auto;
}
.supermarket-name {
    flex: 17;
    font-size: 0.9vw;
    font-weight: bold;
}
.delivery-container input{
    width: 97%;
    font-size: 0.9vw;
    padding: 0.7vw;
    border-radius: 0.5vw;
    border: none;
    box-shadow: 0 0 0.1vw 0.07vw rgba(0,0,0,0.15);
    flex: 90;
}
.delivery-container select{
    width: 97%;
    font-size: 0.9vw;
    padding: 0.7vw;
    border-radius: 0.5vw;
    border: none;
    box-shadow: 0 0 0.1vw 0.07vw rgba(0,0,0,0.15);
    flex: 60;

    /* Oculta la flecha predeterminada del selector */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.delivery-container select:disabled {
    background-color: #a8a8a8;
    color: #2f2f2f;
    opacity: 0.35;
}
.delivery-notes{
    height: 4vw;
    margin-top: 1.5vw;
}
.delivery-price{
    text-align: center;
    font-size: 1vw;
    margin: -1vw 0 2vw 0;
    display:flex;
    justify-content:center;
}
.delivery-button{
    font-size: 1vw;
    padding: 0.6vw 0;
    border-radius: 0.35vw;
    width: 50%;
    background-color: darkred;
    margin: 0.5vw 0 0 25%;
    box-shadow: 0 0 0.15vw 0.15vw rgba(0,0,0,0.15);
}
.order-done-container{
    margin: 10vw auto 0;
    width: 50%;
    height: 25vw;
    text-align: center;
    background-color: rgb(245, 245, 253);
    border-radius: 1vw;
    border-top: 0.2vw solid darkred;
    border-bottom: 0.2vw solid darkred;
    box-shadow: 0 0.1vw 0.4vw 0.07vw rgb(0, 0, 0, 0.3);
}
.order-done-container h2{
    margin-top: 4vw;
    font-size: 2vw;
}
.order-done-container img{
    margin-top: 4vw;
    width: 17%;
}
.mp-info{
    margin-top: 10%;
    height: 20vw;
    padding: 0 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.mp-info h2{
    font-size: 1vw;
    font-weight: normal;
    margin-bottom: 7%;
}
.mp-info p{
    margin-bottom: 5%;
    text-align: left;
    font-size: 1vw;
}