*{
    margin: 0;
    padding: 0;
    font-family:  'Poppins', sans-serif;
}
.background-image{
    height: 100%;
    width: 100%;
    /*background-image: url("images/backgrounds/Landing.png");*/
    background-position: center;
    background-size: cover;
    position: absolute;
}
.elementIZQ{
    /*height: 75%;*/
    width: 50%;
    /*background-image: url("images/backgrounds/Landing15.png") !important;*/
    position: absolute;
    object-fit: cover;
    margin-top: -29%;
    margin-left: -12.5%;
    rotate: 178.5deg;
    scale: 0.99;
}
.elementDER{
    width: 50%;
    /*background-image: url("images/backgrounds/Landing15.png") !important;*/
    position: absolute;
    object-fit: cover;
    margin-top: 27%;
    margin-left: 63.5%;
    rotate: -16deg;
    scale: 1.04;
}
.logo-l {
    height: 4.5vw;
    width: 15vw;
    background-image: url("images/Logo Red 1.png");
    background-position: center;
    background-size: cover;
    border-radius: 0.7vw;
    margin: 1vw auto;
    margin-bottom: 3vw;
}
.login-form{
    width: 23vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*margin: 8% auto 0;*/
    background: rgba(255,255,255, 0.9);
    padding: 2vw;
    box-shadow: 0 0 0.4vw 0.1vw rgba(0,0,0, 0.3);
    text-align: center;
    border-radius: 1vw;
}
.login-form h2{
    color: darkred;
    margin-top: 2vw;
    margin-bottom: 1vw;
    font-size: 1.3vw;
    font-weight: 500;
}
.input-group-l{
    margin-top: 2vw;
    position: center;
}
.input-field-l{
    width: 20vw;
    height: 1.8vw;
    border: none;
    padding: 0.5vw 1vw;
    border-radius: 0.4vw;
    background-color: #e5e5e5;
    margin-top: 1.5vw;
    outline: none;
    font-size: 1vw;
    box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0, 0.1);
}
.input-group scan{
    color: #777;
    font-size: small;
}
.login-btn{
    color: white;
    background-color: darkred;
    width: 22vw;
    height: 2.5vw;
    border-radius: 0.35vw;
    font-size: 1.1vw;
    margin-top: 15%;
    border: none;
    outline: none;
    cursor: pointer;
}
.check-box{
    margin-top: 2vw;
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
}
a{
    color: red;
}
.links2{
    margin-top: 2vw;
    color: #777;
    font-size: 1vw;
}
.alert-user{
    padding: 20px;
    background-color: black;
    color: grey;
    margin-bottom: 15px;
    font-size: 15px;
    text-align: center;
}

@media(max-device-width: 700px){
    .elementIZQ{
        width: 180%;
        /*background-image: url("images/backgrounds/Landing15.png") !important;*/
        position: absolute;
        object-fit: cover;
        rotate: 178deg;
        scale: 0.5;
        margin-top: -96%;
        margin-left: -68%;
    }
    .elementDER{
        width: 180%;
        /*background-image: url("images/backgrounds/Landing15.png") !important;*/
        position: absolute;
        object-fit: cover;
        rotate: -16deg;
        scale: 0.5;
        margin-top: 108%;
        margin-left: -3.5%;
    }
    .login-form{
        width: 80%;
        /*height: 62vh;*/
        position: center;
        margin: 0 auto 0;
        background: white;
        padding: 2vw;
        box-shadow: 0 0 0.6vh 0.01vh rgba(0,0,0,05);
        text-align: center;
        border-radius: 5vw;
    }
    .logo-l {
        height: 18vw;
        width: 70%;
        background-image: url("images/Logo Red 1.png");
        background-position: center;
        background-size: cover;
        border-radius: 1vh;
        margin: 10% auto;
    }
    .login-form h2{
        color: darkred;
        margin-top: 15%;
        margin-bottom: 7%;
        font-size: 5vw;
        font-weight: 500;
    }
    .input-group-l{
        margin-top: 5%;
        position: center;
    }
    .input-field-l{
        width: 75%;
        height: 8vw;
        border: none;
        padding: 0.5vh 1vh;
        border-radius: 0.4vw;
        background-color: #e5e5e5;
        margin-top: 7%;
        outline: none;
        font-size: 4vw;
        box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0, 0.1);
    }
    .input-group scan{
        color: #777;
        font-size: small;
    }
    .login-btn{
        width: 85%;
        height: 10vw;
        border-radius: 0.35vh;
        font-size: 4vw;
        margin-top: 10%;
    }
    .check-box{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2%;
        margin-top: 10%;
        font-size: 3.5vw;
    }
    a{
        color: red;
    }
    .links2{
        margin-top: 10%;
        color: #777;
        font-size: 4vw;
        margin-bottom: 8%;
    }
    .alert-user{
        padding: 20px;
        background-color: black;
        color: grey;
        margin-bottom: 15px;
        font-size: 15px;
        text-align: center;
    }
}