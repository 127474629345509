/* Estilos generales para el modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Estilos para el contenido del modal */
.modal-content {
    background-color: rgb(245, 245, 253); /* Fondo blanco para el contenido del modal */
    border-radius: 1vw;
    box-shadow: 0 0 0.1vw rgba(0, 0, 0, 0.3);
    max-width: 50%; /* Ancho máximo del contenido del modal */
    min-width: 25%;
    padding: 0 0 2vw 0;
}

/* Estilos para el botón de cerrar */
.close-button {
    cursor: pointer;
    font-size: 2.8vw;
    color: darkred;
    margin: 1vw 0 0 92%;
}

@media(max-device-width: 700px){
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .modal-content {
        background-color: rgb(245, 245, 253); /* Fondo blanco para el contenido del modal */
        border-radius: 2vw;
        box-shadow: 0 0 0.1vw rgba(0, 0, 0, 0.3);
        max-width: 80%; /* Ancho máximo del contenido del modal */
        max-height: 70vh;
        padding: 0 0 2vw 0;
        margin-top: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        pointer-events: auto;
    }

    .close-button {
        cursor: pointer;
        font-size: 10vw;
        color: darkred;
        margin: 1vw 0 0 89%;
    }

}