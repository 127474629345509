.pending-order-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    margin: 1vw 0 2vw 0;
}

.orders-container {
    width: 60%;
    height: 80%;
    background-color: white;
    border-radius: 1vw;
    padding: 0 1.5vw;
    overflow-y: scroll;
    overflow-x: hidden;
}

.orders-container h2 {
    font-size: 1.3vw;
    text-align: center;
}

.close-modal {
    margin: 0 0 0 0;
    width: 4%;
    color: darkred;
    font-size: 2vw;
    padding: 0;
}

.products-container {
    width: 98%;
    height: 68%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 1%;
    /*justify-content: flex-start;*/
}

.confirm-bought-button {
    margin: 2.3vw 25%;
    width: 50%;
    background-color: darkred;
}