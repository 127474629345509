.product {
    width: 95%;
    height: 5vw;
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    border-radius: 0.5vw;
    background-color: white;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1vw;
    padding: 1vw 0 2vw 0;
    font-size: 1vw;
}

.product img {
    width: 12%;
    border-radius: 0.5vw;
}

.name {
    width: 100%;
    margin: -5% 0 0 10%;
    font-weight: 500;
}

.price {
    width: 100%;
    margin: 1vw 0 0 10%;
    font-weight: 500;
}

.amount {
    width: 30%;
    font-size: 1.1vw;
    font-weight: 400;
}

.supermarket {

}

.link-button {
    background-color: darkred;
    color: white;
    width: 25%;
    border-radius: 0.5vw;
    font-size: 1vw;
}

.checkbox {
    width: 5%;
    height: 50%;
    margin-left: 2%;
}