*{
    margin: 0;
    padding: 0;
    font-family:  'Poppins', sans-serif;
}
.background-image{
    height: 100%;
    width: 100%;
    /*background-image: url("images/backgrounds/Landing.png") ;*/
    background-position: center;
    background-size: cover;
    position: absolute;
}
.logo-r {
    height: 3.3vw;
    width: 11vw;
    background-image: url("images/Logo Red 1.png");
    background-position: center;
    background-size: cover;
    border-radius: 0.7vw;
    margin: 0.6vw auto;
    margin-bottom: 4vw;
}
.register-form{
    width: 23vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.9);
    padding: 2vw;
    box-shadow: 0 0 0.4vw 0.1vw rgba(0,0,0, 0.3);
    text-align: center;
    border-radius: 1vw;
}
.register-form h4{
    color: darkred;
    margin-top: -2vw;
    margin-bottom: 1vw;
    font-size: 1vw;
    font-weight: normal;
}
.input-group-r{
}
.input-field-r{
    width: 20vw;
    height: 1.8vw;
    border: none;
    padding: 0.5vw 1vw;
    border-radius: 0.4vw;
    background-color: #e5e5e5;
    margin-top: 1.5vw;
    outline: none;
    font-size: 1vw;
    box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0, 0.1);
}
.register-btn{
    color: white;
    background-color: darkred;
    width: 22vw;
    height: 2.5vw;
    border-radius: 0.35vw;
    font-size: 1.1vw;
    margin-top: 15%;
    border: none;
    outline: none;
    cursor: pointer;
}
.social-btn{
    background-color: firebrick;
}
a{
    text-decoration: none;
}
span{
    color: #777;
    font-size: 12px;
    bottom:68px;
}
.links3{
    margin-top: 2vw;
    color: #777;
    font-size: 1vw;
}
.alert-register{
    padding: 20px;
    background-color: black;
    color: grey;
    margin-bottom: 15px;
    font-size: 15px;
    text-align: center;
}

@media(max-device-width: 700px){
    .register-form{
        width: 80%;
        /*height: 60%;*/
        position: center;
        margin: 0 auto 0;
        background: white;
        padding: 2vw;
        box-shadow: 0 0 0.6vh 0.01vh rgba(0,0,0,05);
        text-align: center;
        border-radius: 5vw;
    }
    .logo-r {
        height: 18vw;
        width: 70%;
        background-image: url("images/Logo Red 1.png");
        background-position: center;
        background-size: cover;
        border-radius: 1vh;
        margin: 10% auto;
    }
    .register-form h4{
        color: darkred;
        margin-top: 15%;
        margin-bottom: 7%;
        font-size: 4vw;
        font-weight: 500;
    }
    .input-field-r{
        width: 75%;
        height: 8vw;
        border: none;
        padding: 0.5vh 1vh;
        border-radius: 0.4vw;
        background-color: #e5e5e5;
        margin-top: 6%;
        outline: none;
        font-size: 4vw;
        box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0, 0.1);
    }
    .input-group scan{
        color: #777;
        font-size: small;
    }
    .register-btn{
        width: 85%;
        height: 10vw;
        border-radius: 0.35vh;
        font-size: 4vw;
        margin-top: 10%;
    }
    .check-box{
        margin-top: 10%;
        font-size: 3.5vw;
    }
    a{
        color: red;
    }
    .links3{
        margin-top: 10%;
        color: #777;
        font-size: 4vw;
        margin-bottom: 8%;
    }
    .alert-user{
        padding: 20px;
        background-color: black;
        color: grey;
        margin-bottom: 15px;
        font-size: 15px;
        text-align: center;
    }
}