.manage-orders-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

.pending-orders-container {
    width: 50%;
    height: 28vw;
    margin: 3vw auto;
    border-radius: 1vw;
    background-color: rgb(245, 245, 250);
    box-shadow: 0 0 0.15vw 0.07vw rgba(0,0,0,0.2);
    padding: 2vw;
    overflow-y: scroll;
    overflow-x: hidden;
}

.pending-orders-container h2{
    padding-bottom: 0.6vw;
    border-bottom: 2px solid #ccc;
}

.pending-order {
    height: 4vw;
    padding: 1vw;
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5vw;
    background-color: white;
    box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.15);
    margin: 2vw 0 0 0;
    font-size: 1.1vw;
}

.email {
    width: 35%;
}

.date {
    width: 14%;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
}

.order-price {
    width: 10%;
}

.see-more {
    width: 10%;
    background-color: darkred;
    border-radius: 0.3vw;
    margin-right: 2%;
}

