@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;1,200&family=Rubik+Iso&display=swap');

*{
    font-family: 'Montserrat', sans-serif;
}

button {
    cursor: pointer;
}

.container{
    overflow-x: hidden;
}
.home-banner {
    margin: 0 10% 1vw;
    width: 80vw;
    height: 20.5vw;
    box-shadow: 0 0 0.3vw 0.1vw rgba(0,0,0,0.25);
}

.home-banner-mobile {
    display: none;
}

.titulo{
    background-color: rgb(245, 245, 253);
    color: #4d4d4d;
    margin-top: 2%;
    margin-right: 7%;
    margin-left: 8%;
    border-radius: 0.25vw;
    padding: 7px 7px 7px 15px;
    font-weight: 500;
    font-size: 1.4vw;
    border-left: 0.2vw solid rgb(150, 0, 0);
    box-shadow: 0 0 0.1vw 0.07vw rgba(0,0,0,0.15);
}
.card:hover{
    transform: translateY(-2px);
    box-shadow: 0 0.2vw 0.6vw 0.15vw rgba(0,0,0,0.15);
}

.nombre{
    height: 30%;
    width: 90%;
    margin-top: 7%;
    margin-left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(50, 50, 50);
    font-size: 1.2vw;
    font-weight: normal;
    /*background: #61dafb;*/
}
.card{
    border-radius: 0.6vw;
    box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.15);
    text-align: center;
    font-size: medium;
    height: 13.5vw;
    width: 12vw;
    transition: all 0.075s;
    background-color: white;
    /*border-bottom: 0.2vw solid rgb(150, 0, 0);*/
}
.card-disabled{
    filter: grayscale(100%);
    border-radius: 0.6vw;
    box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.15);
    text-align: center;
    font-size: medium;
    height: 13.5vw;
    width: 12vw;
    background-color: rgb(230, 230, 240);
    cursor: default;
}
.category-image-container {
    width: 100%;
    height: 8vw;
}
.card img{
    height: 8vw;
    margin-top: 8%;
}
.card-disabled img{
    height: 8vw;
    margin-top: 8%;
}
.category-container {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin-left: 8%;
}
.scroll-arrows-buttons {
    margin-top: 8.5%;
    box-shadow: 0 0 0.1vw 0.07vw rgba(0,0,0,0.15);
    border-radius: 2vw;
    width: 2.5vw;
    height: 2.5vw;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: darkred;
    cursor: pointer;
    background-color: rgb(245, 245, 253);
}
.categoria{
    margin-top: 1vw;
    margin-bottom: 4.5%;
    display: flex;
    gap: 3vw;
    width: 89%;
    height: 13vw;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 1vw 0 3.5vw 0.5vw;
}

.categoria button {
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.categoria::-webkit-scrollbar {
    height: 0.5vw;
}
.categoria::-webkit-scrollbar-track {
    height: 0.5vw;
    background-color: rgb(240, 240, 247);
    margin-left: 20vw;
    margin-right: 25vw;
    border-radius: 1vw;
}
.categoria::-webkit-scrollbar-thumb {
    background-color: rgb(150, 0, 0);
    border-radius: 1vw;
}

@media(max-device-width: 700px){
    .container {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .home-banner {
        /*margin: 15% 0 3% -6%;*/
        /*width: 130vw;*/
        /*height: 35vw;*/
        /*box-shadow: 0 0 0 0 rgb(0,0,0);*/
        display: none;
    }

    .home-banner-mobile {
        margin-top: 17%;
        display: block;
        width: 100%;
        height: 37vw;
        box-shadow: 0 0 1vw 1vw rgba(0,0,0,0.15);
    }

    .titulo {
        background-color: rgb(245, 245, 253);
        color: #4d4d4d;
        margin: 10% 15px -4% 15px;
        border-radius: 0.5vw;
        padding: 2% 0 2% 4%;
        font-weight: 500;
        font-size: 5vw;
        border-left: none;
        /*border-bottom: 0.5vw solid rgb(210, 210, 210);*/
        box-shadow: 0 0 0.17vw 0.08vw rgba(0,0,0,0.17);
    }

    .scroll-arrows-buttons {
        display: none;
    }

    .category-container {
        margin-left: 5%;
        width: 90%;
    }

    .categoria {
        width: 100%;
        margin-top: 10%;
        margin-left: 0;
        margin-bottom: 6.5%;
        display: flex;
        gap: 7vw;
        height: 50vw;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 0 5% 2px;
        /*z-index: 1000;*/
    }

    .categoria::-webkit-scrollbar {
        height: 1.5vw;
    }
    .categoria::-webkit-scrollbar-track {
        height: 1.5vw;
        background-color: rgb(240, 240, 247);
        margin-left: 25vw;
        margin-right: 30vw;
    }
    .categoria::-webkit-scrollbar-thumb {
        background-color: rgb(150, 0, 0);
        border-radius: 1vw;
    }

    .category-container button {
        background-color: transparent;
    }

    .card {
        border-radius: 2vw;
        box-shadow: 0 0 0.5vw 0.4vw rgba(0,0,0,0.1);
        text-align: center;
        font-size: medium;
        height: 90%;
        width: 35vw;
        /*margin-left: 3%;*/
        transition: all 0.075s;
        /*border-bottom: 0.2vw solid rgb(150, 0, 0);*/
        z-index: 1000;

    }

    .card-disabled {
        filter: grayscale(100%);
        border-radius: 2vw;
        text-align: center;
        font-size: medium;
        height: 100%;
        width: 35vw;
        margin-left: 3%;
        transition: all 0.075s;
        background-color: rgb(240, 240, 248);
        cursor: default;
    }

    .category-image-container {
        width: 100%;
        height: 65%;
    }

    .card img{
        height: 80%;
        margin-top: 8%;
    }

    .card-disabled img {
        height: 80%;
        margin-top: 8%;
    }

    .subcategoria {
        margin-left: 0;
        margin-top: 2%;
        margin-right: 5%;
    }

    .nombre {
        margin-top: 1%;
        color: rgb(50, 50, 50);
        font-size: 4.5vw;
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 90%;
        margin-left: 5%;
    }

    .carrito-web {
        display: none;
    }

    .progressbar {
        display: none;
    }
}




