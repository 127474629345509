.texto{
    text-align: center;
    margin-top: 5%;
    margin-bottom: 10%;
    font-size: 220%;
}
.volver{
    margin-left: 34.9%;
    font-size: 220%;
    padding: 2%;
    background-color: darkred;
    width: 50%;
    color: white;
}