*{
    margin: 0;
    padding: 0;
    font-family:  'Poppins', sans-serif;
}
.background-image{
    height: 100%;
    width: 100%;
    /*background-image: url("images/banner1.jpeg");*/
    background-position: center;
    background-size: cover;
    position: absolute;
}
.registerProduct-form{
    width: 25vw;
    position: center;
    margin: 10% auto 0;
    background: white;
    padding: 2vw;
    box-shadow: 0 0 15px 0 rgba(0,0,0,1);
    text-align: center;
    border-radius: 1vw;
}
.input-group-p{
    position: center;
}
.input-field-p{
    width: 20vw;
    height: 1vw;
    padding: 1vw 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin-top: 1vw;
    outline: none;
    font-size: 0.9vw;
}
.input-field-p1{
    width: 20vw;
    height: 2vw;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin-top: 1.5vw;
    outline: none;
    font-size: 0.9vw;
}
.input-image{
    margin-top: 10%;
    margin-left: -15%;
    margin-bottom: 5%;
}
.registerProduct-btn{
    color: white;
    background-color: darkred;
    width: 22vw;
    height: 2.5vw;
    border-radius: 3vw;
    font-size: 1.1vw;
    margin-top: 15%;
    border: none;
    outline: none;
    cursor: pointer;
}
.registerProduct-form h4{
    margin-bottom: 1vw;
}
.alert-product{
    padding: 20px;
    background-color: black;
    color: grey;
    margin-bottom: 15px;
    font-size: 15px;
    text-align: center;
}
.image{
    margin-top: 10%;
    margin-bottom: 3%;
    margin-left: -10%;
}
.producto{
    margin: 3% 8%;
    display: grid;
    justify-items: center;
}



