.cart-container {
    overflow: hidden;
}

.cart-container-2 {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 87%;
}

.cart-header {
    display: flex;
    align-content: center;
    margin: 7vw auto 0;
    width: 87%;
    height: 2.5vw;
    background-color: rgb(245, 245, 253);
    box-shadow: 0 0 0.17vw 0.08vw rgba(0,0,0,0.17);
    border-radius: 0.2vw;
}

.white-rectangle {
    background-color: white;
    width: 100%;
    height: 127px;
    margin-top: -62px;
    position: fixed;
}

.titulo-carrito {
    background-color: rgb(245, 245, 253);
    width: 80%;
    margin-left: -1%;
    /*margin-left: 5%;*/
    /*margin-top: -1vw;*/
    /*border-radius: 0.5vw;*/
    padding: 0.5vw 1vw;
    font-weight: 500;
    font-size: 1.3vw;
    /*border-left: 0.2vw solid rgb(150, 0, 0);*/
    /*box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
}

.jumbo-price {
   margin: 10px 10px 10px 10px;
}

.carrefour-price {
    margin: 10px 10px 10px 10px;
}

.vea-price {
    margin: 10px 10px 10px 10px;
}

.info-modal {
    margin: -1vw auto 0;
    width: 24vw;
    height: 20vw;
    border-radius: 0.3vw;
    padding: 0 3vw 0 3vw;
}

.modal-price-container {
    background-color: white;
    width: 90%;
    height: 3vw;
    border-radius: 0.7vw;
    box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);
    padding: 1vw;
    display: flex;
    align-items: center;
    margin: 1.5vw 0 0 2%;
}

.modal-price-container img {
    width: 7vw;
    height: 7vw;
}

.super-price {
    margin-left: 2vw;
    font-size: 1.1vw;
    display: flex;
}

.super-number {
    font-weight: bold;
    margin-left: 8%;
}

.info-supers {
    width: 28vw;
    /*height: 10vw;*/
    background-color: white;
    font-size: 1vw;
    border-radius: 0.5vw;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 1vw;
    box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);
    position: absolute;
    margin: 7vw -3% 0;
}

.links-modal {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw
}

.buy-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3vw;
    align-items: center;
}

.buying-info {
    width: 80%;
    text-align: justify;
    font-size: 1.1vw;
}

.buying-info-important {
    width: 80%;
    text-align: justify;
    font-size: 1.1vw;
}

.links-modal button {
    width: 70%;
    height: 3vw;
    background-color: darkred;
    color: white;
    border: none;
    border-radius: 0.5vw;
    font-size: 1.1vw;
    font-weight: 400;
    cursor: pointer;
}

@media(max-device-width: 700px){

    .cart-container {
        overflow: hidden;
    }

    .cart-container-2 {
        display: block;
        width: 95%;
        margin: 0 auto;
    }

    .cart-header {
        display: flex;
        align-content: center;
        margin: 21% 5.5% 0;
        width: 89%;
        height: 10vw;
        background-color: rgb(245, 245, 253);
        box-shadow: none;
        border-radius: 0.2vw;
    }

    .title-container{
        background-color: transparent;
        /*border-bottom: 2px solid #ccc;*/
    }

    .titulo-carrito {
        background-color: white;
        width: 100%;
        margin-top: 0;
        display: flex;
        align-items: center;
        padding: 0.5vw 1vw;
        font-weight: 500;
        font-size: 5.5vw;
        color: black;
        border-bottom: 2px solid #ccc;
    }

    .jumbo-price {
        margin: 10px 10px 10px 10px;
    }

    .carrefour-price {
        margin: 10px 10px 10px 10px;
    }

    .vea-price {
        margin: 10px 10px 10px 10px;
    }

    .info-modal {
        margin: -1vw auto 0;
        width: 24vw;
        height: 20vw;
        border-radius: 0.3vw;
        padding: 0 3vw 0 3vw;
    }

    .modal-price-container {
        background-color: white;
        width: 90%;
        height: 3vw;
        border-radius: 0.7vw;
        box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);
        padding: 1vw;
        display: flex;
        align-items: center;
        margin: 1.5vw 0 0 2%;
    }

    .modal-price-container img {
        width: 7vw;
        height: 7vw;
    }

    .super-price {
        margin-left: 2vw;
        font-size: 1.1vw;
        display: flex;
    }

    .super-number {
        font-weight: bold;
        margin-left: 8%;
    }

    .info-supers {
        width: 28vw;
        /*height: 10vw;*/
        background-color: white;
        font-size: 1vw;
        border-radius: 0.5vw;
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 1vw;
        box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);
        position: absolute;
        margin: 7vw -3% 0;
    }

    .buy-modal {
        display: flex;
        flex-direction: column;
        gap: 8vw;
    }

    .links-modal {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .buying-info {
        width: 80%;
        margin-left: 10%;
        text-align: justify;
        font-size: 4vw;
    }

    .buying-info-important {
        width: 80%;
        margin-left: 10%;
        text-align: justify;
        font-size: 4vw;
    }

    .links-modal button {
        width: 80%;
        height: 30px;
        background-color: darkred;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 4vw;
        font-weight: 400;
        cursor: pointer;
    }
}