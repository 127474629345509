/* Accordion.css */

.accordion {
    margin: 2vw 0 0 0;
    border-radius: 0.2vw;
    overflow: hidden;
    width: 78%;
    padding: 0.1vw;
    /*border-bottom: 0.2vw solid rgb(150, 0, 0);*/

}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7vw;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 0.2vw;
    font-size: 0.7vw;
    background-color: rgb(245, 245, 253);
    /*border-left: 0.2vw solid rgb(150, 0, 0);*/
    box-shadow: 0 0 0.17vw 0.08vw rgba(0,0,0,0.17);
    margin: 0.1vw;
}

.accordion-title{
    margin-left: 1%;
}

.icon {
    font-size: 1vw;
    color: darkred;
    transition: transform 0.4s; /* Ajusta la duración de la transición */
    margin-right: 0.7vw;
}

.open .icon { /* Usa .open para aplicar el estilo al icono cuando está abierto */
    transform: rotate(180deg);
}

.closed .icon { /* Usa .closed para aplicar el estilo al icono cuando está cerrado */
    transform: rotate(0deg);
}

.accordion-content {
    padding: -0.5vw 0 3vw;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.7s ease-in-out; /* Agrega una transición a max-height */
}

.open .accordion-content { /* Aplica el estilo al contenido cuando el acordeón está abierto */
    max-height: 45vw; /* Ajusta el valor para que se adapte al contenido real */
}

