.empty-cart{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35vw;
    /*box-shadow: 0 0 0.07vw 0.1vw rgba(0,0,0,0.1);*/
    border-radius: 0.5vw;
    font-size: 1.5vw;
}
.producto-carrito{
    margin-right: -1%;
    display: block;
    justify-items: left;
    margin-top: 2.5vw;
    height: 35vw;
    width: 50vw;
    overflow: scroll;
    scroll-snap-type: y mandatory;
    overflow-x: hidden;
    border-radius: 0.7vw;
    box-shadow: 0 0 0.25vw 0.1vw rgba(0,0,0,0.1);
}
.cart-item-card{
    /*border-radius: 0.5vw;*/
    /*box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
    border-bottom: 2px solid rgb(230, 230, 240);
    /*text-align: center;*/
    /*font-size: medium;*/
    height: 7vw;
    width: 96%;
    padding-bottom: 0.5%;
    margin: 0.2vw 0 0.5% 2%;
    display: flex;
}
.cart-item-card img{
    width: 5.6vw;
    height: 5.6vw;
    margin-top: 1.3%;
    margin-bottom: 6%;
    margin-left: 1.5%;
}

.product-info-container {
    margin: 0.1vw 0 0 7%;
    width: 70%;
}

.prices-selector{
    margin: 0.3vw 0 0 -0.3vw;
    display: flex;
    width: 45%;
    height: 2vw;
    font-size: 1vw;
    border-radius: 0.4vw;
    border: none;
    background-color: white;
    /*box-shadow: 0 0 0.05vw 0.1vw rgba(0,0,0,0.2);*/
    /*flex: 10;*/
}
.price{
    margin-left: 2vw;
    width: 10vw;
    height: 1vw;
    font-size: 1vw;
    display: flex;
    gap: 1vw;
}
.price-number{
    font-weight: bold;
}
.amount-buttons{
    /*flex: 20;*/
    /*width: 30%;*/
    margin: 2.4vw 5% 0 0;
}
.cart-item-name{
    /*margin-left: 8%;*/
    margin-top: 0.3vw;
    font-size: 1.1vw;
    text-align: left;
    font-weight: 500;
    width: 93%;
    height: 3vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    /*flex: 30;*/
}

.producto-carrito::-webkit-scrollbar {
    width: 0.7vw;
    min-width: 5px;
    max-width: 12px;
}
.producto-carrito::-webkit-scrollbar-track {
    /*background-color: rgb(230, 230, 240);*/
    border-radius: 1vw;
}
.producto-carrito::-webkit-scrollbar-thumb {
    /*background-image: linear-gradient(180deg, rgb(190, 190, 200) 10%, red 90%);*/
    background-color: darkred;
    border-radius: 1vw;
}

@media(max-device-width: 700px){

    .empty-cart {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98%;
        height: 50vh;
        /*box-shadow: 0 0 0.07vw 0.1vw rgba(0,0,0,0.1);*/
        border-radius: 0.5vw;
        font-size: 20px;
    }

    .producto-carrito {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        /*display: block;*/
        /*justify-items: left;*/
        margin-top: 15px;
        height: auto;
        padding: 0 0 250px 0;
        border-radius: 0.7vw;
        box-shadow: none;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .cart-item-card {
        /*border-radius: 0.5vw;*/
        /*box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
        border-bottom: 2px solid rgb(230, 230, 240);
        /*text-align: center;*/
        /*font-size: medium;*/
        height: 23vw;
        width: 96%;
        padding-bottom: 0.5%;
        margin: 0.2vw 0 0.5% 2%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .cart-item-card img {
        width: 18vw;
        height: 18vw;
        /*width: 75px;*/
        /*height: 75px;*/
        margin: 0 0 0 1.5%;

    }

    .product-info-container {
        margin: 0 0 0 2%;
        width: 70%;
    }

    .prices-selector {
        margin: 1vw 0 0 -1vw;
        display: flex;
        width: 40vw;
        height: 7vw;
        font-size: 3.5vw;
        border-radius: 0.4vw;
        border: none;
        background-color: white;
        /*box-shadow: 0 0 0.05vw 0.1vw rgba(0,0,0,0.2);*/
        /*flex: 10;*/
    }

    .price {
        margin-left: 0;
        width: 0;
        height: 0;
        font-size: 3.5vw;
        display: flex;
        gap: 0;
    }

    .price-number {
        font-weight: 600;
    }

    .amount-buttons {
        /*flex: 20;*/
        width: 30%;
        margin: 0 0 0 0;
    }

    .cart-item-name {
        margin-left: 0;
        margin-top: 0.3vw;
        font-size: 3.5vw;
        text-align: left;
        font-weight: 500;
        width: 95%;
        height: 12vw;
        overflow: hidden;
        display: flex;
        align-items: center;
    }
}