*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding-top: 3vw;
    background-color: rgba(0, 0, 0, .5);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;
}
.popup-inner{
    margin-top: -6%;
    position: relative;
    padding: 1vw 2.3vw;
    width: 40vw;
    height: 40vw;
    /*max-width: 640px;*/
    background-color: rgb(243, 243, 250);
    border-radius: 1vw;
}

@media(max-device-width: 700px){
    .popup-inner{
        /*margin-top: 100px;*/
        position: relative;
        padding: 5% 5% 5% 5%;
        width: 80%;
        height: 80vh;
        /*max-width: 640px;*/
        background-color: white;
        border-radius: 5vw;
        overflow-y: auto;
    }
}
