*{
    margin: 0;
    padding: 0;
    font-family:  'Poppins', sans-serif;
}
.background-image{
    height: 100%;
    width: 100%;
    /*background-image: url("images/banner1.jpeg");*/
    background-position: center;
    background-size: cover;
    position: absolute;
}
.admin-form{
    width: 22vw;
    height: 32vw;
    position: center;
    margin: 8% auto 0;
    background: white;
    padding: 2.5vw;
    box-shadow: 0 0 15px 0 rgba(0,0,0,1);
    text-align: center;
    border-radius: 1vw;
}
.btn{
    background-color: darkred;
    width: 22vw;
    height: 2.7vw;
    font-size: 1.2vw;
    margin-top: 1vw;
    margin-bottom: 0.9vw;
    text-align: center;
}