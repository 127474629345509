*{
    margin: 0;
    padding: 0;
    font-family:  'Poppins', sans-serif;
}
.or{
    font-size: 1.2vw;
    /*background: rgba(255,255,255, 0.5);*/
    width: 2vw;
    margin: -1vw auto 0;
}
hr{
    width: 97%;
}
.form{
    width: 24vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.9);
    padding: 2vw 2vw 3vw;
    box-shadow: 0 0 0.4vw 0.1vw rgba(0,0,0, 0.2);
    text-align: center;
    border-radius: 1vw;
    z-index: 1000;

    opacity: 0;
}
.form-animation {
    transition: opacity 0.65s ease, transform 0.65s ease;
    opacity: 1;
    transform: translate(-50%, -50%) translateY(-10%);
}
.background-image{
    height: 100%;
    width: 100%;
    /*background-image: url("images/backgrounds/Landing15.png") !important;*/
    /*background-position: center;*/
    /*background-size: cover;*/
    /*position: absolute;*/
    overflow: hidden;
}
.element1{
    /*height: 75%;*/
    width: 50%;
    /*background-image: url("images/backgrounds/Landing15.png") !important;*/
    position: absolute;
    object-fit: cover;
    margin-top: -43%;
    margin-left: -13%;
    rotate: 178.5deg;
    scale: 0.99;

    opacity: 0;
}
.element2{
    width: 50%;
    /*background-image: url("images/backgrounds/Landing15.png") !important;*/
    position: absolute;
    object-fit: cover;
    margin-top: 40%;
    margin-left: 67%;
    rotate: -16deg;
    scale: 1.04;

    opacity: 0;
}
.element1-animation {
    transition: opacity 0.55s ease, transform 1.2s ease;
    opacity: 1;
    /*transform: translate(0, 0) translateY(28%);*/
    transform: translate(0, 0) translateY(-28%);
}
.element2-animation {
    transition: opacity 0.55s ease, transform 1.2s ease;
    opacity: 1;
    transform: translate(0, 0) translateY(-26%);
}
.background-filter {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}
/*.background-image::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-image: url("images/backgrounds/Landing.png") !important;*/
/*    background-position: center;*/
/*    background-size: cover;*/
/*}*/
.logo-p {
    height: 6vw;
    width: 20vw;
    background-image: url("images/Logo Red 1.png");
    background-position: center;
    background-size: cover;
    border-radius: 0.7vw;
    margin: 0.6vw auto 4vw;
}
.form h1{
    color: black;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
}
.form h2{
    color: firebrick;
    margin-bottom: 30px;
}
.buttons{
    margin-top: 5.5vw;
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
}
.btns{
    color: white;
    background-color: darkred;
    width: 90%;
    height: 2.7vw;
    border-radius: 0.35vw;
    font-size: 1.2vw;
    border: none;
    outline: none;
    cursor: pointer;
}
.links{
    margin-top: 40px;
    margin-left: 194px;
    color:red;
    font-size: small;
}
@media(max-device-width: 700px){
    .element1{
        width: 180%;
        /*background-image: url("images/backgrounds/Landing15.png") !important;*/
        position: absolute;
        object-fit: cover;
        margin-top: -150%;
        margin-left: -70%;
        rotate: 178deg;
        scale: 0.5;

        opacity: 0;
    }
    .element2{
        width: 180%;
        /*background-image: url("images/backgrounds/Landing15.png") !important;*/
        position: absolute;
        object-fit: cover;
        margin-top: 190%;
        margin-left: 20%;
        rotate: -16deg;
        scale: 0.5;

        opacity: 0;
    }
    .element1-animation {
        transition: opacity 0.55s ease, transform 1.2s ease;
        opacity: 1;
        /*transform: translate(0, 0) translateY(28%);*/
        transform: translate(0, 0) translateY(-60%);
    }
    .element2-animation {
        transition: opacity 0.55s ease, transform 1.2s ease;
        opacity: 1;
        transform: translate(0, 0) translateY(-95%);
    }
    .or{
        font-size: 5vw;
        background: white;
        width: 10vw;
        margin: -1vw auto 0;
    }
    .background-filter {
        /*background-color: transparent;*/
    }
    .form{
        width: 90%;
        /*height: 40vh;*/
        /*position: center;*/
        margin: 5% auto 0;
        background: transparent;
        padding: 2% 2% 10% 2%;
        box-shadow: none;
        text-align: center;
        border-radius: 5vw;
        border: none;
    }
    .logo-p {
        height: 21vw;
        width: 78%;
        background-image: url("images/Logo Red 1.png");
        background-position: center;
        background-size: cover;
        border-radius: 15px;
        margin: 10% auto;
    }
    .form h1{
        color: black;
        margin-bottom: 30px;
        font-size: 30px;
        font-weight: bold;
    }
    .form h2{
        color: firebrick;
        margin-bottom: 30px;
    }
    .buttons{
        margin-top: 20%;
        display: flex;
        flex-direction: column;
        gap: 4vw;
    }
    .btns{
        color: white;
        background-color: darkred;
        width: 80%;
        height: 10vw;
        border-radius: 1.5vw;
        font-size: 4.5vw;
        margin: 0;
        border: none;
        outline: none;
        cursor: pointer;
    }
    hr {
        /*opacity: 0;*/
        width: 80%;
        margin: 0;
    }
    .links{
        margin-top: 40px;
        margin-left: 194px;
        color:red;
        font-size: small;
    }
}
