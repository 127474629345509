*{
    margin: 0;
    padding: 0;
    font-family:  'Poppins', sans-serif;
}
.background-image{
    height: 100%;
    width: 100%;
    /*background-image: url("images/banner1.jpeg");*/
    background-position: center;
    background-size: cover;
    position: absolute;
}
.registerSupermarket-form{
    width: 25vw;
    position: center;
    margin: 10% auto 0;
    background: white;
    padding: 2vw;
    box-shadow: 0 0 15px 0 rgba(0,0,0,1);
    text-align: center;
    border-radius: 1vw;
}
.input-group-s{
    position: center;
}
h4{
    margin: auto;
    font-size: 1.2vw;
}
.input-field-s{
    width: 20vw;
    height: 1vw;
    padding: 1vw 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin-top: 1vw;
    outline: none;
    font-size: 0.9vw;
}
.direction{
    margin-top: 15%;
    margin-bottom: -1%;
    font-weight: bold;
    font-size: 1vw;
}
.registerSupermarket-btn{
    color: white;
    background-color: darkred;
    width: 22vw;
    height: 2.5vw;
    border-radius: 3vw;
    font-size: 1.1vw;
    margin-top: 15%;
    border: none;
    outline: none;
    cursor: pointer;
}


