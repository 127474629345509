*{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif !important;
}

button {
    border: none;
}

.header-products {
    display: flex;
    align-content: center;
    margin: 7vw 5.5% 0;
    width: 88%;
    height: 2.5vw;
    background-color: rgb(245, 245, 253);
    box-shadow: 0 0 0.17vw 0.08vw rgba(0,0,0,0.17);
    border-radius: 0.15vw;
}
.go-back {
    flex: 5;
    margin-left: 2%;
}
.title-container{
    display: flex;
}
.title-product{
    font-size: 1.3vw;
    font-weight: normal;
    background-color: rgb(245, 245, 253);
    margin-bottom: 1vw;
    width: 85%;
    height: 1.5vw;
    margin-left: -1%;
    /*border-radius: 0.5vw;*/
    padding: 0.5vw 1vw;
    /*border-left: 0.2vw solid rgb(150, 0, 0);*/
    /*box-shadow: 0.1vw 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
}
.product-info-icon{
    margin-left: auto;
    margin-right: 1.5%;
    margin-top: 0.5vw;
    width: 1.5vw;
    height: 1.5vw;
}
.info-title {
    width: 60%;
    /*height: 10vw;*/
    background-color: white;
    font-size: 0.9vw;
    border-radius: 0.2vw;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0.5vw;
    box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);
    position: absolute;
    margin-top: 0.2vw;
    margin-left: 14%;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3vw 5.5% 0 ;
    width: 88%;
}

.filters-mobile {
    display: none;
}

.order-by {
    width: 100%;
    /*margin-left: 27%;*/
    /*margin-right: 7.5%;*/
    font-size: 1.1vw;
    font-weight: bold;
    border-bottom: 0.1vw solid rgb(206, 206, 206);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.7vw;
}

.order-by a {
    color: black;
}

.order-icon {
    display: none;
}

.product-loader {
    display: flex;
    width: 100%;
    height: 10vw;
    margin-top: 10%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.loader-web {

}

.loader-mobile {
    display: none;
}

.order-selector{
    background-color: white;
    /*color: white;*/
    /*margin-left: 6%;*/
    font-size: 1.1vw;
    font-weight: normal;
    width:67%;
    border-radius: 0.3vw;
    padding: 0.4vw;
    border: none;
    /*box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0,0.1);*/
}
.search-container {
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters-order {
    display: flex;
    width: 29%;
}

.search-products-icon {
    color: #505050;
    width: 1.5vw;
    height: 1.5vw;
    margin-left: -6%;
}

.search-input {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 0.15vw 0.1vw rgba(0,0,0,0.15);
    padding: 0.7vw;
    border: none;
    border-radius: 0.7vw;
    width: 100%;
    font-size: 1.1vw;
    /*border-left: 0.3vw solid rgb(150, 150, 150);*/
    /*border-right: 0.3vw solid rgb(150, 150, 150);*/
    /*border-bottom: 0.2vw solid darkred;*/
}
.search-input:focus {
    border-color: darkred; /* Cambiar el color del borde al hacer clic */
    outline: none; /* Quitar el resaltado predeterminado del enfoque */
}

.products-list-container {
    display: flex;
    justify-content: space-between;
    width: 87%;
    margin-left: 6%;
}

.product-filters {
    width: 26%;
    box-shadow: 0 0 0.17vw 0.08vw rgba(0,0,0,0.17);
    background-color: rgb(245, 245, 253);
    /*background-color: white;*/
    height: fit-content;
    /*max-height: 67vw;*/
    margin: 3.5% 0 0 0;
    border-radius: 0.2vw;
}
.product-filters h3{
    padding: 0.7vw 0 0.4vw 0.3vw;
    width: 90%;
    border-bottom: 0.2vw solid darkred;
    margin-left: 0.8vw;
    margin-bottom: 1vw;
    font-size: 1.1vw;
    display: flex;
    justify-content: space-between;
}
.product-filters h4{
    padding: 0.7vw 0 0.4vw 0.3vw;
    width: 90%;
    border-bottom: 1px solid #bbb;
    margin-left: 0.8vw;
    font-size: 1vw;
    font-weight: 500;
}
.empty-filters {
    color: darkred;
    cursor: pointer;
}
.individual-filter {
    min-height: 10vw;
    max-height: 15vw;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 1vw 7% 1vw 2%;
}
.filter-content{
    display: flex;
    flex-direction: column;
    padding: 1vw 1.5vw;
}
.filter-content label {
    margin-top: -1vw;
    margin-bottom: 2vw;
    display: flex;
    align-items: center;
    font-size: 1vw;
}
.filter-content input {
    width: 1vw;
    height: 1vw;
    margin-right: 0.5vw;
}
.producto{
    /*background-color: #61dafb;*/
    width: 70%;
    margin: 3.5% 0 2% 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, 22.5%);
    grid-gap: 2vw;
    justify-items: center;
}



.buttons-container{
    display: flex;
    align-items: center;
    margin-top: 0.3vw;
}
.btn-mas{
    background-color: rgb(230, 230, 240);
    width: 2vw;
    height: 2vw !important;
    margin: 1vw -9.5vw 1vw 0 !important;
    font-weight: bolder;
    font-size: 1vw;
    padding: 0.4vw 0;
    color: rgb(180, 0, 0);
    text-align: center;
    transition: all 0.07s;
}

.popup-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.2vw solid rgb(150, 0, 0);
}
.popup-name{
    font-size: 1.3vw;
    font-weight: bold;
    padding: 0.5vw;
    /*border-bottom: 0.2vw solid rgb(150, 0, 0);*/
    border-radius: 0.3vw;
    max-width: 80%;
}
.image-container{
    width: 13vw;
    height: 13vw;
    margin: -1.5vw 0 -1.5vw 1.5vw;
    box-shadow: 0 0 0.7vw 0.05vw rgba(0,0,0,0.2);
}
.product-info{
    width: 50%;
    margin-top: -1.5vw;
    margin-left: 4%;
}
.field{
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    margin: 0.5vw 0 1vw;
}
.field h3{
    font-size: 1.1vw;
    font-weight: 500;
    margin-right: 5%;
}
.field p{
    font-size: 1.1vw;
    font-weight: 600;
}

.field-smarket-container {
    display: flex;
    align-items: center;
    gap: 5%;
    margin: 2.5vw 0 1vw;
}

.field-smarket {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    width: 70%;
    /*border-left: 0.2vw solid rgb(250, 0, 0);*/
    /*border-right: 0.2vw solid rgb(250, 0, 0);*/
    box-shadow: 0 0 0.5vw 0.05vw rgba(0,0,0,0.3);
    padding: 3% 4%;
    border-radius: 0.5vw;
    background-color: #f00000;
}
.field-smarket img {
    width: 40% !important;
}
.field-smarket h3{
    font-size: 1.1vw;
    font-weight: 500;
    margin-right: 5%;
}
.field-smarket p{
    font-size: 1.1vw;
    font-weight: 600;
    color: white;
    width: 70%;
    display: flex;
    justify-content: center;
    /*margin-right: 3%;*/
}

.product-discount {
    color: #f00000;
    font-size: 1.1vw;
    font-weight: 600;
}

.popup img{
    width: 100%;
    height: 100%;
}
.popup-header .close-btn{
    color: darkred;
    margin-left: auto;
    margin-top: -0.2vw;
    font-size: 2.5vw;
    padding: 0;
    background-color: transparent;
    /*box-shadow: 0 0 0.2vw 0.05vw rgba(0,0,0,0.3);*/
    border-radius: 5vw;
}
.supermarket-product{
    width: 100%;
    margin-bottom: 2%;
    padding: 1.5% 0;
    font-weight: inherit;
    display: inline-flex;
    border-radius: 0.3vw;
    box-shadow: 0 0 0.15vw 0.05vw rgba(0,0,0,0.15);
    background-color: white;
}
.names-container{
    width: 100%;
    margin-left: 2%;
}
.super-name{
    width: 95%;
    font-weight: bold;
    font-size: 0.9vw;
}
.price-name{
    margin-top: 1%;
    width: 95%;
    font-size: 0.85vw;
}
.popup-body{
    height: 16vw;
    border-radius: 0.9vw;
    /*margin-top: 4%;*/
    font-size: medium;
    overflow: hidden;
    /*scroll-snap-type: y mandatory;*/
    /*overflow-x: hidden;*/
    padding: 1vw;
    /*background-color: rgb(245, 245, 253);*/
    /*border: 0.1vw solid rgb(150, 150, 150);*/
    /*box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);*/
}
.popup-body img {
    width: 10%;
    height: 10%;
    margin: 0 1vw;
    /*border-radius: 0.6vw;*/
    user-select: none; /*hace que no se pueda seleccionar el elemento (que se ponga en azul)*/
}
.popup-body::-webkit-scrollbar {
    width: 0.7vw;
    min-width: 5px;
    max-width: 12px;
}
.popup-body::-webkit-scrollbar-track {
    /*background-color: rgb(230, 230, 240);*/
    border-radius: 1vw;
}
.popup-body::-webkit-scrollbar-thumb {
    /*background-image: linear-gradient(180deg, rgb(190, 190, 200) 10%, red 90%);*/
    background-color: darkred;
    border-radius: 1vw;
}
.show-more-products-button{
    width: 20%;
    height: 2.5vw;
    margin: 2vw 26% 10vw;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    font-size: 1.2vw;
    background-color: rgb(245, 245, 253);
    color: darkred;
    border-radius: 0.2vw;
    box-shadow: 0 0 0.1vw 0.15vw rgba(0,0,0,0.1);
}
.info-product-modal {
    width: 60%;
    /*height: 3vw;*/
    background-color: white;
    font-size: 0.9vw;
    border-radius: 0.3vw;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0.5vw;
    box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);
    position: absolute;
    margin-top: 15vw;
    margin-left: 20%;
}

.register-modal {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vw;
}

.register-modal-info {
    width: 80%;
    font-size: 1.2vw;
}

.register-modal-buttons {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
}

.register-modal-button {
    color: white;
    background-color: darkred;
    width: 100%;
    height: 2.7vw;
    border-radius: 0.35vw;
    font-size: 1.2vw;
    border: none;
    outline: none;
    cursor: pointer;
}

.popup-product-container {
    display: flex;
    margin-top: 4vw;
}

.individual-filter::-webkit-scrollbar {
    width: 0.55vw;
    min-width: 5px;
    max-width: 12px;
}
.individual-filter::-webkit-scrollbar-track {
    background-color: rgb(230, 230, 240);
    border-radius: 1vw;
}
.individual-filter::-webkit-scrollbar-thumb {
    /*background-image: linear-gradient(180deg, rgb(190, 190, 200) 10%, red 90%);*/
    background-color: darkred;
    border-radius: 1vw;
}

@media(max-device-width: 700px){
    .header-products {
        display: flex;
        align-content: center;
        margin: 25% 0 0;
        width: 100%;
        background-color: transparent;
        box-shadow: 0 0 0 0 rgba(0,0,0,0.17);
        border-radius: 0;
    }

    .go-back {
        display: none;
    }

    .title-product{
        background-color: transparent;
        width: 100%;
        margin: 0 15px 0 15px;
        /*border-radius: 2vw;*/
        padding: 0 0 2% 4%;
        font-size: 5vw;
        font-weight: 500;
        height: 6vw;
        border-bottom: 2px solid #ccc;
    }

    .product-info-icon{
        display: none;
    }

    .filters{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 13% 5% 0;
    }

    .search-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: rgb(245, 245, 253);
        box-shadow: 0 0 1vw 0.1vw rgba(0,0,0,0.15);
        border-radius: 5vw;
    }

    .search-products-icon {
        color: #505050;
        width: 8%;
        height: 8%;
        margin-right: 3%;
    }

    .search-input {
        background-color: rgb(245, 245, 253);
        box-shadow: none;
        border: none;
        border-radius: 5vw;
        width: 86%;
        font-size: 4vw;
        padding: 3% 7% 3% 7%;
    }

    .filters-order {
        width: 100%;
        display: flex;
        justify-content: center;
        box-shadow: 0 0 0.5vw 0.5vw rgba(0,0,0,0.1);
        /*border: 1px solid #ccc;*/
        margin-top: 7%;
    }

    .filters-mobile {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.8vw;
        font-weight: 400;
        padding: 0;
        color: #525252;
        background-color: transparent;
    }

    .filter-icon {
        rotate: 90deg;
        color: darkred;
        margin-right: 8%;
    }

    .order-by {
        width: 60%;
        background-color: transparent;
        margin-right: 0;
        display: flex;
        position: relative;
        border: none;
        padding-bottom: 0;
    }

    .order-by a {
        display: none;
    }

    .order-icon {
        display: block;
        color: darkred;
        position: absolute;
        left: 13%; /* Ajusta el valor según tu preferencia */
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none; /* Evita que el ícono sea interactivo */
    }

    .order-selector {
        background-color: white;
        color: #525252;
        font-size: 3.8vw;
        font-weight: normal;
        width: 100%;
        padding: 6% 5% 6% 25%;
        text-align: center;

        appearance: none; /* Elimina el estilo predeterminado del sistema (en algunos navegadores) */
        -webkit-appearance: none;
    }

    .product-loader {
        display: flex;
        width: 100%;
        height: 40vw;
        margin-top: 20%;
        justify-content: center;
        align-items: center;
    }

    .loader-web {
        display: none;
    }

    .loader-mobile {
        display: block;
    }

    .container-margins {
        width: 100vw;
        /*margin: 0 15px 0 15px;*/
    }

    .products-list-container {
        display: flex;
        width: 100%;
        margin: 0;
    }


    .product-filters {
        display: none;
    }

    .producto{
        /*background-color: #61dafb;*/
        width: 100%;
        margin: 8% 15px 0 15px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 47%);
        grid-gap: 5vw;
        justify-items: center;
    }

    .show-more-products-button{
        width: 60%;
        height: 9vw;
        margin: 7% auto 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5vw;
        background-color: rgb(245, 245, 253);
        color: darkred;
        border-radius: 0.2vw;
        box-shadow: 0 0 0.1vw 0.15vw rgba(0,0,0,0.1);
    }



    /*MODAL*/
    .popup-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid rgb(150, 0, 0);
    }
    .popup-name{
        font-size: 4.5vw;
        font-weight: bold;
        padding: 0 0 2vw 0;
        /*border-bottom: 0.2vw solid rgb(150, 0, 0);*/
        border-radius: 0.3vw;
        max-width: 80%;
    }
    .image-container{
        width: 60%;
        height: 45vw;
        margin: 0 auto 7%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #61dafb;
    }
    .product-info{
        width: 100%;
        margin-top: 5%;
        margin-left: 4%;
    }
    .field{
        display: flex;
        /*justify-content: space-between;*/
        align-items: center;
        margin: 5% 0 3%;
    }
    .field h3{
        font-size: 4vw;
        font-weight: 500;
        margin-right: 5%;
    }
    .field p{
        font-size: 4vw;
        font-weight: 600;
    }

    .field-smarket {
        margin-top: 1vh;
    }

    .field-smarket p{
        font-size: 4vw;
    }

    .product-discount {
        color: #f00000;
        font-size: 16px;
        font-weight: 600;
    }

    .popup img{
        width: 100%;
        height: 100%;
    }
    .popup-header .close-btn{
        color: darkred;
        margin-left: auto;
        margin-top: -5%;
        font-size: 10vw;
        padding: 0;
        width: 10vw;
        /*height: 2.5vw;*/
        background-color: white;
        box-shadow: 0 0 0 0 rgba(0,0,0,0.3);
        border-radius: 5vw;
    }
    .supermarket-product{
        width: 100%;
        margin-bottom: 4%;
        font-weight: inherit;
        display: inline-flex;
        border-radius: 2vw;
        box-shadow: 0 0 0.3vw 0.05vw rgba(0,0,0,0.15);
        background-color: white;
        padding: 3% 0;
    }
    .names-container{
        width: 100%;
        margin-left: 2%;
    }
    .super-name{
        margin-top: 0;
        width: 95%;
        font-weight: bold;
        font-size: 3.5vw;
    }
    .price-name{
        margin-top: 3%;
        width: 95%;
        font-size: 3.5vw;
    }
    .info-icon {
        display: none;
    }

    .popup-body{
        height: 280px;
        border-radius: 3vw;
        margin-top: 8%;
        font-size: medium;
        overflow: scroll;
        scroll-snap-type: y mandatory;
        overflow-x: hidden;
        padding: 3%;
        background-color: rgb(245, 245, 253);
        /*border-left: 0.2vw solid rgb(150, 0, 0);*/
        box-shadow: 0 0 0.3vh 0.05vh rgba(0,0,0,0.15);
    }

    .popup-body img {
        width: 20%;
        height: 20%;
        margin: 0.8vw 1vw;
        border-radius: 0.6vw;
    }

    .popup-product-container {
        display: block;
        margin-top: 4vw;
    }

    .popup-inner::-webkit-scrollbar {
        display: none;
    }

    .register-modal {
        width: 75vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8vw;
        height: auto;
    }

    .register-modal-info {
        width: 80%;
        font-size: 3.8vw;
    }

    .register-modal-buttons {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 3.5vw;
    }

    .register-modal-button {
        color: white;
        background-color: darkred;
        width: 100%;
        height: 9vw;
        border-radius: 1.5vw;
        font-size: 4.5vw;
        border: none;
        outline: none;
        cursor: pointer;
    }

}
