.icono {
    margin-top: 73vh;
    margin-left: 90vw;
    height: 3vw;
    width: 3vw;
    background-color: darkred;
    padding: 2%;
    border-radius: 20%;
    color: rgb(230, 230, 240);
    position: fixed;
}

.cart-amount{
    color: darkred;
    background-color: white;
    position: fixed;
    margin-top: 72vh;
    margin-left: 95.5vw;
    height: 2vw;
    width: 2vw;
    font-size: 1.2vw;
    /*border: 2px solid darkred;*/
    box-shadow: 0 0 0.1vw 0.15vw rgb(0, 0, 0, 0.16);
    border-radius: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
}